div[data-tip=true] {
  cursor: help;
}
.tooltip {
  max-width: 400px;
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 1.4em;
  text-align: left;
}

.textInput {
  width: 100%;
  -webkit-appearance: none;
}
.sanatoriumTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.sanatoriumType {
  color: #777;
  font-weight: 200;
  font-size: .85em;
}

.sanatoriumLinks {
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
}

.sanatoriumLinks__profkurort, .sanatoriumLinks__alean, .sanatoriumLinks__profkurort {
  margin-right: 8px;
}

.sanatoriumLinks__alean:after {
  content: '';
  background: url(/static/alean.png) no-repeat;
  background-size: contain;
  height: 13px;
  width: 13px;
  display: block;
}

.sanatoriumLinks__cash {
  display: inline-block;
  margin-right: 5px;
}

.sanatoriumLinks__cash:after {
  content: '';
  background: url(/static/images/cash.png) no-repeat;
  background-size: contain;
  height: 19px;
  width: 19px;
  display: block;
}

.sanatoriumLinks__home {
  display: inline-block;
}

.sanatoriumLinks__home:after {
  content: '';
  background: url(/static/images/home.png) no-repeat;
  background-size: contain;
  height: 19px;
  width: 19px;
  display: block;
}

.sanatoriumLinks__park {
  display: inline-block;
}

.sanatoriumLinks__park:after {
  content: '';
  background: url(/static/images/park.png) no-repeat;
  background-size: contain;
  height: 19px;
  width: 19px;
  display: block;
}

.sanatoriumLinks__man {
  display: inline-block;
  margin-right: 5px;
}

.sanatoriumLinks__man:after {
  content: '';
  background: url(/static/images/man.png) no-repeat;
  background-size: contain;
  height: 17px;
  width: 17px;
  display: block;
}

.sanatoriumLinks__water {
  display: inline-block;
}

.sanatoriumLinks__water:after {
  content: '';
  background: url(/static/images/water.png) no-repeat;
  background-size: contain;
  height: 19px;
  width: 19px;
  display: block;
}

.sanatoriumLinks__children {
  display: inline-block;
}

.sanatoriumLinks__children:after {
  content: '';
  background: url(/static/images/children.png) no-repeat;
  background-size: contain;
  height: 19px;
  width: 19px;
  display: block;
}

.sanatoriumLinks__pool {
  display: inline-block;
}

.sanatoriumLinks__pool:after {
  content: '';
  background: url(/static/images/pool.png) no-repeat;
  background-size: contain;
  height: 19px;
  width: 19px;
  display: block;
}

.sanatoriumLinks__sleep {
  display: inline-block;
}

.sanatoriumLinks__sleep:after {
  content: '';
  background: url(/static/images/sleep.png) no-repeat;
  background-size: contain;
  height: 19px;
  width: 19px;
  display: block;
}

.sanatoriumLinks__food {
  display: inline-block;
}

.sanatoriumLinks__food:after {
  content: '';
  background: url(/static/images/food.png) no-repeat;
  background-size: contain;
  height: 19px;
  width: 19px;
  display: block;
}

.sanatoriumLinks__directly {
  display: inline-block;
  margin-right: 8px;
}

.sanatoriumLinks__directly:after {
  content: '';
  background: url(/static/images/directly.png) no-repeat;
  background-size: contain;
  height: 19px;
  width: 19px;
  display: block;
}

.sanatoriumLinks__info {
  display: inline-block;
  margin-right: 2px;
}

.sanatoriumLinks__info:after {
  content: '';
  background: url(/static/images/info.png) no-repeat;
  background-size: contain;
  height: 14px;
  width: 14px;
  display: block;
}

.sanatoriumLinks__link {
  display: inline-block;
  margin-left: 8px;
}

.sanatoriumLinks__link:after {
  content: '';
  background: url(/static/images/link.png) no-repeat;
  background-size: contain;
  height: 10px;
  width: 10px;
  display: block;
}

.sanatoriumLinks__delfin:after {
  content: '';
  background: url(/static/delfin.png) no-repeat;
  background-size: contain;
  height: 13px;
  width: 13px;
  display: block;
}

.sanatoriumLinks__profkurort:after {
  content: '';
  background: url(/static/profkurort.png) no-repeat;
  background-size: contain;
  height: 13px;
  width: 13px;
  display: block;
}

.phone:after {
  content: '';
  background: url('/static/phone.svg') no-repeat;
  width: 13px;
  height: 16px;
  margin-left: 4px;
  display: block;
}

.sanatoriumInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cancelColumn {
  white-space: pre-wrap !important;
}

.priceRow {
  text-align: center;
  vertical-align: middle;
  line-height: 1.45em;
  font-size: 1em;
  color: #333;
}

.mealRow {
  text-align: center;
  vertical-align: middle;
  line-height: 1.45em;
  font-size: 1em;
  color: #333;
}

.boolRow {
  text-align: center;
  vertical-align: middle;
  line-height: 1.45em;
  font-size: 1.1em;
  color: grey;
}

.boolRow__value--true {
  color: green;
  font-weight: bold;
}

.medProfile {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
}
.medProfile__extra, .medProfile__main {
  display: flex;
  padding: .1em .3em;
}
.medProfile__extra::before {
  display: block;
  content: '';
  background: #ccc;
  width: 1px;
  margin-right: .3em;
}
.medProfile__extra .icon:not(.active){
  filter: grayscale(1);
}

.medProfile__extra .icon:hover {
  filter: grayscale(0);
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0,0,0,0.07);
}

.regionRow {
  display: flex;
  align-items: center;
  white-space: pre-line !important;
}

.aquapark h4 {
  margin-bottom: 0;
}

.city__td {
  padding: 8px 0;
}

.rt-tr-group:first-child .rt-tr .position__boolRow div .__react_component_tooltip {
  top: 0 !important;
}